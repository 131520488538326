import { useState } from "react";
import { CountDown } from "./components/CountDown";
import logo from "./HONEY.png";
import { ReactComponent as DexieLogo } from "./dexie.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import {
  TOTAL_JAR_SUPPLY,
  UPCOMING_DROP_AMOUNT,
} from "./utils/constants";
import { getNextAirdropDate } from "./utils/helpers";

function App() {
  const [isExpired, setIsExpired] = useState(false);
  const upcomingDrop = getNextAirdropDate();

  let curs = document.querySelector(".cursor");

  document.addEventListener("mousemove", (e) => {
    let x = e.pageX;
    let y = e.pageY;
    if (curs) {
      window.requestAnimationFrame(() => {
        // @ts-ignore
        curs.style.transform =
          "translate3d(" + (x - 175) + "px, " + (y - 175) + "px, 0)";
        // @ts-ignore
        curs.style.opacity = 1;
      });
    }
  });


  return (
    <div>
      <div className="bg-wrapper">
        <div className="cursor"></div>
        {
          // @ts-ignore
          [...Array(7).keys()].map((i): number => {
            const className: string = `hc-row ${
              i % 2 !== 0 ? "hc-row-moved" : ""
            }`;
            // @ts-ignore
            return (
              <div className={className}>
                {/* @ts-ignore */}
                {[...Array(20).keys()].map((i): number => (
                  <div className="hexagon"></div>
                ))}
              </div>
            );
          })
        }

        <div className="fader"></div>
      </div>
      <div className="container-fluid pt-5 px-0">
        <div className="container-md">
          <div className="row h-100 align-items-stretch justify-content-center text-center">
            <div className="col-12 mx-auto mb-5 no-select">
              <img src={logo} className="img-fluid coin-logo" alt="logo" />
            </div>
            <div className="col-12 col-lg-10 col-xl-8 text-light">
              <h1 className="display-2 text-light mb-5 no-select">HONEY</h1>
              <p className="text-light fs-5 fw-light">
                <span className="fw-normal text-honey">HONEY</span> is the
                <span className="fw-normal text-honey">
                  {" "}
                  official currency{" "}
                </span>{" "}
                of the{" "}
                <a
                  href="https://hairybearsocial.club"
                  className="text-light"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hairy Bear Social Club
                </a>
                .
              </p>
              <p className="text-light fs-5 fw-light">
                You will be able to use{" "}
                <span className="fw-normal text-honey">HONEY</span> to purchase
                our upcoming NFTs and other goods.
              </p>
              <p className="text-light fs-5 fw-light">
                <span className="fw-normal text-honey">HONEY</span> will be
                distributed through{" "}
                <span className="fw-normal text-honey">recurring airdrops</span>{" "}
                to{" "}
                <a
                  href="https://jars.hairybearsocial.club"
                  className="text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  JAR
                </a>{" "}
                owners. <br /> We will also be holding giveaways in our{" "}
                <a
                  href="https://discord.gg/nx4mXMs3xw"
                  className="text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  Discord
                </a>
                .
              </p>
              <p className="mb-5 text-light fs-5 fw-light">
                The airdrop amount is{" "}
                <span className="fw-normal text-honey">
                  {UPCOMING_DROP_AMOUNT.toLocaleString()} HONEY,
                </span>
                <br />
                evenly distributed among all JAR owners{" "}
                <span className="fw-normal text-honey">every month.</span>
                <br />
              </p>
              <div
                className={
                  "rounded-pill bg-honey text-dark p-4 text-center no-select honey-pill mx-auto mb-5 " +
                  (isExpired === false ? "" : "pulse")
                }
              >
                {isExpired === false ? (
                  <>
                    <p className="my-2 text-uppercase">Next airdrop in:</p>
                    <CountDown
                      expiryTimestamp={upcomingDrop}
                      onExpire={() => setIsExpired(true)}
                    />
                    <hr className="mx-auto dashed-border" />

                    <div className="container">
                      <div className="row justify-content-center">
                        <div className="col-12 col-sm-6">
                          {" "}
                          <p className="mb-2 text-uppercase">
                            HONEY reward per JAR:
                          </p>
                          <div className="my-2">
                            <span className="h1 fw-bold">
                              {
                                (UPCOMING_DROP_AMOUNT / TOTAL_JAR_SUPPLY).toLocaleString(undefined, {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                  }
                                )
                              }
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <h4 className="fw-bold my-2 text-uppercase">
                    Airdrop in progress
                  </h4>
                )}
              </div>

              <div className="container-fluid p-5 px-0">
                <div className="container-md">
                  <div className="row text-center">
                    <div className="col-12">
                      {" "}
                      <p className="mb-4 text-uppercase fw-bold">TRADE NOW ON</p>
                    </div>
                  </div>
                  <div className="row text-center justify-content-center">
                    <div className="col-7 col-sm-6 col-md-4 mb-4 px-3">
                      <a
                        href="https://dexie.space/offers/HONEY/any"
                        className="text-white btn-icon"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <DexieLogo />
                      </a>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="container-fluid my-3">
        <div className="container-md">
          <div className="row text-center justify-content-center">
            <div className="col-12 col-sm-10 col-md-7">
              <p className="my-5 text-light">
                <strong>Name:</strong> Hairy Bear Honey (HONEY) <br />
                <strong>Asset ID:</strong> <br />
                <code className="text-light">
                  b7c0b9c3a59898a8a9e9be1e9d77d63be558bc57060257a2a667c8ca7ab687a8
                </code>
              </p>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="d-flex col-12 justify-content-center no-select">
              <p className="text-white text-center m-0">
                Copyright © 2022 - 2024
                <br />
                <a
                  href="https://hairybearsocial.club"
                  className="text-white"
                  target="_blank"
                  rel="noreferrer"
                >
                  Hairy Bear Social Club
                </a>
              </p>
            </div>
            <div className="d-flex col-12 justify-content-center">
              <a
                href="https://twitter.com/hairybearsc"
                className="btn btn-link text-white btn-icon"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-twitter"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                </svg>
              </a>
              <a
                href="https://discord.gg/nx4mXMs3xw"
                className="btn btn-link text-white btn-icon"
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  className="bi bi-discord"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
