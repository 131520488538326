import { FunctionComponent } from "react";
import { useTimer } from "react-timer-hook";

interface Props {
  expiryTimestamp: Date;
  onExpire: () => void;
}

export const CountDown: FunctionComponent<Props> = ({
  expiryTimestamp,
  onExpire
}: Props) => {
  const { seconds, minutes, hours, days } = useTimer({
    expiryTimestamp,
    onExpire,
  });

  return (
    <div id="clock-c" className="countdown my-2">
      <span className="h1 fw-bold">{days}</span> {days === 1 ? "day" : "days"}
      <span className="h1 fw-bold">{hours}</span> hr{" "}
      <span className="h1 fw-bold">{minutes}</span> min{" "}
      <span className="h1 fw-bold">{seconds}</span> sec{" "}
    </div>
  );
};
